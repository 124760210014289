<template>
    <div class="wrapper">
        <div class="article_box_left" ref="left">
            <article>
                <div v-for="item in ArticleList" :key="item.id" class="article_box">
                    <div class="article_image">
                        <div class="demo-image__preview">
                            <el-image
                                    :src="item.firstPicture"
                                    :preview-src-list="[item.firstPicture]">
                            </el-image>
                        </div>
                    </div>
                    <div class="article_text">
                        <router-link class="article_text_category" :to="`category/${item.cate}`"><p>
                            {{item.category.name}}</p></router-link>
                        <p class="article_text_time">{{item.create_time.slice(0,10)}}</p>
                        <p class="article_text_title">
                            <router-link :to="`blog/${item.id}`">{{item.title}}</router-link>
                        </p>
                        <div class="article_text_content" id="hellomarkdown"><p v-html="item.description"></p></div>

                        <div class="article_text_none"></div>
                        <div class="article_tag">
                            <hr/>
                            <div class="article_tag_item">
                                <router-link :to="`/tag/${value.id}`" v-for="value in item.tags" :key="value.id"
                                             :class="value.color">{{value.tag_name}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
            <div style="text-align: center;margin-top: 2em;">
                <el-pagination @current-change="handleCurrentChange" :current-page="queryInfo.pageNum"
                               :page-count="totalPage"
                               layout="prev, pager, next" background>
                </el-pagination>
            </div>
        </div>
        <div class="article_box_right" ref="right" >
            <img :src=todayImg content="upgrade-insecure-requests" alt="">
        </div>
    </div>
</template>

<script>
    import {getBlogs} from '@/api/blog'
    import { getBlogsByTag } from '../../api/blog'

    export default {
        name: "Article",
        data() {
            return {
                picture: '',
                queryInfo: {
                    pageNum: 1,
                    pageSize: 5,
                    type: null,
                    watch: null
                },
                tagList: [],
                ArticleList: [],
                categoryList: [],
                totalPage: 0,
                todayImg:''
            }
        },
        created() {
            this.getData()
        },
        methods: {
            getData() {
                getBlogs(this.queryInfo).then(res => {
                    this.totalPage = Math.ceil(res.data.total / 5)
                    this.ArticleList = res.data.list
                    this.categoryList = res.data.categories
                }),
                getBlogsByTag().then(res => {
                    this.todayImg = res.result.image
                    console.log(this.todayImg);
                })
            },
            handleCurrentChange(newPage) {
                this.queryInfo.pageNum = newPage
                this.getData()
            },
        },
    }
</script>

<style lang="less" scoped>
    .wrapper {
        padding-top: 3%;
        padding-bottom: 3%;
        width: 85%;
        margin: auto;

        .article_box_left {
            width: 70%;
            display: inline-block;
            margin-right: 4%;
            vertical-align: top;

            .article_box {
                width: 100%;
                padding-bottom: 2%;
                display: table;
                table-layout: fixed;
                word-break: break-all;

                .article_image {
                    width: 35%;
                    padding-right: 2%;
                    display: table-cell;
                    vertical-align: middle;

                    .el-image {
                        border-radius: 20px;
                        width: 100%;
                        height: auto;
                        /*font-size: 0;*/
                        display: block;
                    }
                }

                .article_text {
                    position: relative;
                    width: 85%;
                    vertical-align: top;
                    padding: 2%;
                    display: table-cell;
                    color: #454552;
                    background: aliceblue;
                    box-sizing: border-box;
                    border-radius: 30px;
                    margin: 0 auto -50px;


                    .article_text_none {
                        position: relative;
                        width: 100%;
                        height: 32px;
                        margin-top: .5em;
                    }

                    .article_tag {
                        width: 94%;
                        position: absolute;
                        bottom: 1em;
                        height: 32px;

                        .article_tag_item {

                            margin-top: .5em;

                            a {
                                text-decoration: none;
                                color: white;
                                margin-left: 2em;
                                position: relative;
                                padding: 0.5em 1.2em;
                                font-size: .8em;

                                &:before {
                                    position: absolute;
                                    content: '';
                                    transform: translateY(-50%) translateX(50%) rotate(-45deg);
                                    top: 50%;
                                    right: 100%;
                                    background-color: inherit;
                                    width: 1.65em;
                                    height: 1.65em;

                                }

                                &:after {
                                    position: absolute;
                                    content: '';
                                    top: 50%;
                                    left: -0.2em;
                                    margin-top: -0.2em;
                                    background-color: #fff;
                                    width: .4em;
                                    height: .4em;
                                    border-radius: 5em;
                                }
                            }

                        }
                    }

                    .article_text_category {
                        position: absolute;
                        background-color: #ff4943;
                        right: 10%;
                        display: flex;
                        text-align: center;
                        align-items: center;
                        text-decoration: none;
                        font-size: 1em;
                        padding: 1% 1% .5% 1%;
                        

                        p {
                            font-size: 1.5em;
                            color: white;
                            writing-mode: vertical-lr;
                            text-decoration: none;
                            z-index:100
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            top: 60%;
                            left: 0;
                            border: 2em solid transparent;
                            border-left: 2em solid #ff4943;
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            top: 60%;
                            right: 0;
                            border: 2em solid transparent;
                            border-right: 2em solid #ff4943;
                        }
                    }

                    .article_text_time {
                        color: white;
                        font-size: 1.5em;
                        background-color: #454552;
                        padding: 1% 1% 1% 2%;
                        border-radius: 15px;
                        margin-bottom: 2% !important;

                    }

                    .article_text_title {
                        text-decoration: none;
                        color: black;
                        font-size: 1.5em;
                        font-weight: bold;
                        line-height: 2em;
                        text-align: center;

                        a {
                            text-decoration: none;
                            color: black;
                            transition: all 500ms;
                            display: inline-block;

                            &:hover {
                                transform: scale(1.1);
                            }
                        }
                    }


                    .article_text_content {
                        text-align: justify;
                        margin-top: 2em;
                        font-size: 1em;
                        line-height: 1.8em;
                        color: #454552;
                        white-space: pre-line;
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: -24px;
                        margin-top: -12px;
                        border: 12px solid transparent;
                        border-right: 12px solid aliceblue;
                    }
                }


            }

        }

        .article_box_right {
            width: 23%;
            display: inline-block;
            vertical-align: top;

            img {
                border-radius: 20px;
                width: 100%;

            }
        }
    }
</style>