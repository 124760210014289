<template>
    <div id="header" ref="header" style="height:100vh">
        <div class="inner">
            <header style="position:relative;">
                <img src="../../assets/img/head.jpg" alt="">
                <div class="name" style="font-size: 36px; margin-top: 20px; font-weight: bold; font-family: 'Times New Roman', Times, serif;">Ieko</div>
            </header>
        </div>
        <div class="wrapper">
                    <i class="ali-iconfont icon-down" @click="scrollToMain"></i>
            </div>
        <div class="wave1"></div>
        <div class="wave2"></div>
    </div>
</template>

<script>
    export default {
        name: "Header",
        data() {
            return {
                loaded: false,
            }
        },
        methods: {
            //平滑滚动至正文部分
            scrollToMain() {
                window.scrollTo({top: this.$refs.header.clientHeight, behavior: 'smooth'})
            }
        },
    }
</script>


<style lang="less" scoped>
    #header {
        position: relative;
        background-image: url("../../assets/img/bg1.jpg");
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;
        color: #fff;
        text-align: center;
        cursor: default;

        .inner {
            position: relative;
            z-index: 100;
            margin: 0;
            display: inline-block;

            img {
                height: 150px;
                width: 150px;
                box-sizing: border-box;
                border-radius: 50%;
                box-shadow: 2px 2px 12px #b1b1b1, -2px -2px 12px #b1b1b1;
            }

            h1{
                margin-top: 20px;
            }
        }
    }

    #header:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }

    .wrapper {
        position: absolute;
    width: 100px;
    bottom: 150px;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 26px;
    z-index: 100;

        a {
            transition: background-color 0.35s ease-in-out, color 0.35s ease-in-out, border-bottom-color 0.35s ease-in-out;
        }
    }

    .wrapper i {
        font-size: 60px;
        opacity: 0.5;
        cursor: pointer;
        position: absolute;
        top: 55px;
        left: 20px;
        animation: opener .5s ease-in-out alternate infinite;
        transition: opacity .2s ease-in-out, transform .5s ease-in-out .2s;
    }

    @keyframes opener {
        100% {
            top: 65px
        }
    }

    .wrapper i:hover {
        opacity: 1;
    }

    .wave1 {
        background: url("../../assets/img/wave1.png") repeat-x;
        height: 75px;
        width: 100%;
    }

    .wave2 {
        background: url('../../assets/img/wave2.png') repeat-x;
        height: 90px;
        width: calc(100% + 100px);
        left: -100px;
    }

    .wave1, .wave2 {
        position: absolute;
        bottom: 0;
        transition-duration: .4s, .4s;
        z-index: 80;
        user-select: none;
    }
</style>