<template>
	<div>
		<Header></Header>
		<div class="article_box_wrapper">
			<Article></Article>
		</div>
	</div>
</template>

<script>
	import Header from "../../components/index/Header";
	import Article from "../../components/index/Article";

	export default {
		name: "Index",
		components: {Header,Article},
	}
</script>

<style>
	#leimu{
		display: none;
	}
</style>